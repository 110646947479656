/* NotFound.css */
.not-found-container {
    text-align: center;
    margin-top: 100px;
  }
  
  .not-found-title {
    font-size: 48px;
    color: #ff5722; /* Example color */
  }
  
  .not-found-message {
    font-size: 24px;
    color: #757575; /* Example color */
  }