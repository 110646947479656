.ActiveBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0 0 20px 0;
}
.btnActive{
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background: #F4F2F8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.shareCotainerSix{
    background: #F4F2F8 !important;
}