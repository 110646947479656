.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    z-index: 10000;
}
.content_img_header{
    border-radius: 8px;
    border: 2px solid #3D5E46;
    width: 129px;
    height: 129px;
}
.content_img_header img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.content_txt h1{
    color: #3D5E46;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}
.content_txt p{
    max-width: 800px;
    color: #3D5E46;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    margin: 0;
}
.btn_copy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.btn_copy input{
    max-width: 300px;
    outline: none;
    border: none;
    border-radius: 50px;
    border: 2px dashed #4AA262;
    text-align: center;
    color: #3D5E46;
    font-size: 32px;
    font-weight: 700;
    line-height: 125%; /* 50px */
    padding: 12px 0;
    border-radius: 35px;
}
.btn_copy button{
    border-radius: 50px;
    border: 2px solid #4AA262;
    background: #4AA262;
    outline: none;
    /* width: 438px;
    height: 100px; */
    color: #FFF;
    font-size: 32px;
    font-weight: 700;
    line-height: 125%; /* 50px */
    width: 300px;
    padding: 12px 0;
}
.btn_copy button a{
    text-decoration: none;
    color: #fff !important;
}


@media(max-width:767px){
   .content_img_header{
       width: 120px;
       height: 120px;
   }
   .content_txt h1{
       font-size: 24px;
   }
   .content_txt p{
       font-size: 16px;
   }
   
   .btn_copy input{
       width: 191px;
       height: 55px;
       font-size: 24px;
   }
   .btn_copy button{
       width: 191px;
   height: 55px;
       font-size: 16px;
   }
   .content a{
       font-size: 14px;
   }
   }