.home{
    width: 100%;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home > div{
    width: 100%;
    height: 100% !important;
}