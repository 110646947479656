.CouponSix_main{
    width: 100%;
    min-height: 100vh !important;
    background: #F9F9F9;
    display: flex;
    justify-content: flex-start;
    direction: rtl; 
    background: #fff;
    /* padding: 20px; */
}
.CouponSix_main .container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background: #fff;
    /* margin: 40px !important; */
    border-radius: 12px;
    box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
}