main{
    width: 100%;
    /* height: 100%; */
    min-height: 80vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    overflow: hidden;
    /* position: relative; */
    /* margin-bottom: 20vh; */
  }

  .topSvg{
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}
.center_svg{
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-40%);
    z-index: -1;
}
.bottomSvg{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    margin-top: auto;
}
footer a{
    text-decoration: none;
}
footer p{
    margin: 0;
    z-index: 1000;

}
footer p , footer a{
    color: #3D5E46;
    font-size: 15px;
    font-weight: 700;
    line-height: 125%; /* 18.75px */
}