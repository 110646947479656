.HeaderSix {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.HeaderSix .bgImg {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

}
.HeaderSix .bgImg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px 12px 0 0;

}

.HeaderSix .bgImg img {
  max-width: 100%;
}
.shape{
    position: absolute;
    bottom: -26px;
    z-index: 90;
}
.roundedImg{
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    bottom: -10%;
    z-index: 100;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.roundedImg img{
    width: 100%;
}
@media(max-width:767px){
  .roundedImg{
    width: 64px;
    height: 64px;
  }  
}