.footerFive {
    /* padding: 20px 0 0 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2) !important;
    position: relative;
    width: 100%;
    padding: 20px 0;
  }
  .footerFive ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .footerFive ul li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
  .footerFive p{
    font-size: 16px;
    color: #555;
  }