.ContentSix{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    gap: 15px;
}
.ContentSix h1 {
  color: #212b36;
  text-align: center;
  font-size: 22px;
  font-family: 'cairo';
  font-style: normal;
  font-weight: 900;
  line-height: 32px; /* 100% */
}
.ContentSix p {
  color: #808080;
  text-align: center;
  font-family: 'cairo';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  width: 80%;
}
@media(min-width:767px){
    .ContentSix h1 {
        color: #212b36;
        text-align: center;
        font-size: 42px;
        font-family: 'cairo';
        font-style: normal;
        font-weight: 900;
        line-height: 40px; /* 100% */
      }
      .ContentSix p {
        color: #808080;
        text-align: center;
        font-family: 'cairo';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3;
      }
}