.Coupon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
main{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}
.coupont{
    padding-top: 60px;
}
.top_right{
    position: absolute;
    top: -40px;
    right: -8%;
    z-index: -1;
    background: url('../../assets/images/top_right.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .1;
    width: 357px;
    height: 357px;
    /* display: none; */
}
.top_left{
    position: absolute;
    top: 80px;
    left: -15%;
    transform: rotate(140deg);
    z-index: -1;
    background: url('../../assets/images/top_left.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .1;
    width: 407.325px;
    height: 407.325px;
    /* display: none; */

}
.bottom_right{
    position: absolute;
    bottom: 0px;
    right: -10%;
    z-index: -1;
    background: url('../../assets/images/bottom_right.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .1;
    width: 382px;
    height: 382px;
    /* display: none; */

}
.bottom_left{
    position: absolute;
    bottom: 0px;
    left: -5%;
    z-index: -1;
    background: url('../../assets/images/bottom_left.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .1;
    width: 258px;
    height: 258px;
    /* display: none; */
}


.top_right_phone{
    position: absolute;
    top: -40px;
    right: -8%;
    z-index: -1;
    background: url('../../assets/images/top_right.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .2;
    width: 185px;
    height: 185px;
    /* display: none; */
}
.top_left_phone{
    position: absolute;
    top: 200px;
    left: -15%;
    transform: rotate(140deg);
    z-index: -1;
    background: url('../../assets/images/top_left.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .2;
    width: 185px;
    height: 185px;
    /* display: none; */

}
.bottom_right_phone{
    position: absolute;
    bottom: 50px;
    right: -40%;
    z-index: -1;
    background: url('../../assets/images/bottom_right.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .2;
    width: 317px;
    height: 317px;
    /* display: none; */

}
.bottom_left_phone{
    position: absolute;
    bottom: 0px;
    left: -20%;
    z-index: -1;
    background: url('../../assets/images/bottom_left.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: .2;
    width: 195px;
    height: 195px;
}
