.footert{
    background: #000;
    width: 100%;
    padding: 30px 0;
    /* margin-top: 80px; */
    /* height: 100px; */
}
.footert .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footert .container p,.footert .container .links_footer a{
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    line-height: 125%; /* 18.75px */

}
.footerThree{
    margin-top: 30px !important;
}
.footer_four{
    background: transparent;
    margin-top: 0;
}
.footer_four .container p,.footer_four .container .links_footer a{
    color: #011B3C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 17.5px */
}

.links_footer span{
    color: #fff;
    font-weight: 700;
}
.twoFooter{
    background: transparent;
    color: #000;
}
.twoFooter .container p,.twoFooter .container .links_footer a{
    color: var(--d-9-d-9-d-9, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}
.twoFooter .container .links_footer a{
    text-decoration-line: underline;
}