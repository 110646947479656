.mainThree{
    width: 100%;
    height: 100%;
    background: url('../../assets/images/bgbody.png');
    display: flex;
    justify-content: flex-start !important;
}
@media(max-width:767px){
    .mainThree{
        background: url('../../assets/images/bgmob.png');
    } 
}
.mainThree .container{
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    flex-direction: column;
}
.headerThree{
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.headerThree h1{
    color: #FFF;
    font-size: 32px;
    font-weight: 700;
    line-height: 125%; /* 50px */
}
@media(max-width:767px){
    .headerThree h1{
        font-size: 18px;
    } 
}